<template>
  <div>
    <div class="filter-panel">
      <CSSelect i-width="36rpx" style="width: 230px; margin-right: 0">
        <el-date-picker
          type="datetime"
          placeholder="请选择开始时间"
          v-model="queryParams.startTime"
          style="width: 214px"
          format="yyyy-MM-dd HH:mm"
          value-format="yyyy-MM-dd HH:mm"
          prefix-icon="el-icon-time"
          :editable="false"
        >
        </el-date-picker>
      </CSSelect>
      <span class="inline-gray"> </span>
      <CSSelect i-width="36rpx" style="width: 230px">
        <el-date-picker
          type="datetime"
          placeholder="请选择结束时间"
          v-model="queryParams.endTime"
          style="width: 214px"
          format="yyyy-MM-dd HH:mm"
          value-format="yyyy-MM-dd HH:mm"
          prefix-icon="el-icon-time"
          :editable="false"
        >
        </el-date-picker>
      </CSSelect>
      <CSSelect style="margin-right: 20px">
        <select v-model="queryParams.type">
          <option value="" style="color: #000" >申报人身份不限</option>
          <option v-for="(name, index) in identity" :key="index" :value="index">
            {{ name }}
          </option>
        </select>
      </CSSelect>
      <CSSelect style="margin-right: 20px">
        <select v-model="queryParams.buildingId" style="width: 174px">
          <option value="" >全部楼栋</option>
          <option
            v-for="(item, index) in building"
            :key="item + index"
            :value="item.code"
          >
            {{ item.name }}
          </option>
        </select>
      </CSSelect>
      <div
        style="
          vertical-align: text-bottom;
          display: inline-block;
          padding-left: 0px;
        "
      >
        <input
          type="text"
          placeholder="搜索申报人/手机号/装修公司"
          v-model="waitSearch"
          style="
            color: #000;
            width: 220px;
            height: 30px;
            padding-left: 10px;
            border-radius: 4px;
            border: 1px solid #979797;
            margin-right: 20px;
          "
        />
      </div>
      <button type="button" class="btn btn-primary btn-sm" @click="waitQuery()">
        查询
      </button>
    </div>
    <div class="result-panel">
      <CSTable :thead-top="filterHeight" style="margin-top: 20px">
        <template v-slot:thead>
          <tr>
            <th class="text-center">提交时间</th>
            <th class="text-center">申报人</th>
            <th class="text-center">申报人身份</th>
            <th class="text-center">楼栋房间</th>
            <th class="text-center">房间面积</th>
            <th class="text-center">装修项目</th>
            <th class="text-center">装修公司</th>
            <th class="text-center">装修日期</th>
            <th class="text-center">操作</th>
          </tr>
        </template>
        <template v-slot:tbody>
          <tr v-for="process in ApprovalPendingList" :key="process.id">
            <td class="text-center" style="min-width: 125px">
              {{ process.submitTime || "-" }}
            </td>
            <td class="text-center" style="min-width: 125px">
              <span
                v-if="process.declarantName"
                class="allow-click"
                @click="checkDeclarantName(process)"
              >
                {{ process.declarantName }}
              </span>
              <template v-else> -</template>
            </td>
            <td class="text-center" style="min-width: 125px">
              {{ process.type === 1 ? "业主" : "租客" }}
            </td>
            <td class="text-center" style="min-width: 125px">
              {{ process.buildingName + process.floor + "层" + process.roomNo }}
            </td>
            <td class="text-center" style="min-width: 125px">
              {{ process.space?process.space+'㎡': "-" }}
            </td>
            <td class="text-center" style="min-width: 125px">
               <span
                       v-if="process.items"
                       class="allow-click"
                       @click="queryItems(process.items)"
               >
                查看
              </span>
            </td>
            <td class="text-center" style="min-width: 125px">
              <span style="margin: 0">
                <p style="margin-bottom:5px">{{ process.decorationCompany }}</p>
              <p style="margin-bottom: 0">
                {{ process.decorationName }}【{{ process.decorationPhone }}】
              </p>
              </span>
            </td>
            <td class="text-center" style="min-width: 125px">
              {{ process.decorationStart + "至" + process.decorationEnd }}
            </td>
            <td class="text-center" style="min-width: 125px">
              <div class="btn-group">
                <button
                  type="button"
                  class="btn btn-primary dropdown-toggle"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  操作
                </button>

                <ul class="dropdown-menu">
                  <li :style="isApproverId">
                    <a
                      style="width: 100%; text-decoration: none"
                      @click="lookDecorationInfo(process.id)"
                    >
                      审批
                    </a>
                  </li>
                </ul>
              </div>
            </td>
          </tr>
        </template>
      </CSTable>
      <Pagination />
    </div>
    <!--查看个人信息-->
    <CSDialog
      dialog-title="查看申报人"
      dialog-width="480px"
      dialog-visible
      :dialogVisible="ApprovalPendingFlag"
      :dialogShowConfirmBtn="false"
      dialogCancelBtnText="关闭"
      @onClose="ApprovalPendingFlag = false"
      dialog-header-class="alert-bg"
    >
      <template v-slot:dialog-content>
        <div class="dialog-content">
          <div class="dialog-form">
            <div class="dialog-form-field" style="margin-bottom: 10px">
              <div class="field-name">姓名</div>
              <div>{{ ApprovalPendingInfo.declarantName }}</div>
            </div>
            <div class="dialog-form-field" style="margin-bottom: 10px">
              <div class="field-name">电话</div>
              <div>
                {{ ApprovalPendingInfo.declarantPhone }}
              </div>
            </div>
            <div class="dialog-form-field" style="margin-bottom: 10px">
              <div class="field-name">身份证号</div>
              <div>
                {{ ApprovalPendingInfo.declarantIdCard }}
              </div>
            </div>
          </div>
        </div>
      </template>
    </CSDialog>

    <!--查看装修项-->
    <CSDialog
            dialog-title="查看装修项"
            dialog-width="480px"
            dialog-visible
            :dialogVisible="processItemsFlag"
            :dialogShowConfirmBtn="false"
            dialogCancelBtnText="关闭"
            @onClose="processItemsFlag = false"
            dialog-header-class="alert-bg"
    >
      <template v-slot:dialog-content>
        <div class="dialog-content">
          <div class="dialog-form">
             <span style="font-size: 24px">{{processItems}}</span>
          </div>
        </div>
      </template>
    </CSDialog>
  </div>
</template>

<script>
import dayjs from "dayjs";
import CSSelect from "@/components/common/CSSelect";
import Pagination from "@/components/Pagination.vue";
import CSTable from "@/components/common/CSTable";
import {
  queryBuildingUrl,
  queryApprovalPending,
  isApprovalUrl,
} from "@/requestUrl";
import CSDialog from "@/components/common/CSDialog";
export default {
  name: "ApprovalPending",
  components: {
    CSSelect,
    Pagination,
    CSTable,
    CSDialog,
  },
  created() {
    this.buttonState();
    this.queryBuilding();
    this.query();
    this.$vc.on(this.$route.path, "pagination_page", "event", this.query);
    window.addEventListener("keydown",this.queryEnter)
  },
  deactivated(){
    window.removeEventListener("keydown",this.queryEnter);
  },
  beforeDestroy(){
    window.removeEventListener("keydown",this.queryEnter);
  },
  data() {
    return {
      isApproverId: {},
      filterHeight: 0,
      identity: {
        1: "业主",
        2: "租客",
      },
      state: "",
      queryParams: {
        startTime: dayjs().subtract(30, "day").format("YYYY-MM-DD 00:00"),
        endTime: dayjs().format("YYYY-MM-DD 23:59"),
        type: "",
        buildingId: "",
        search: "",
      },
      waitSearch:"",
      building: [],
      ApprovalPendingList: [],
      ApprovalPendingFlag: false,
      ApprovalPendingInfo: {},
      processItems:"",
      processItemsFlag:false,
    };
  },
  methods: {
    queryEnter(e){
      if(e.keyCode == 13){
        this.query();
      }
    },
    waitQuery(){
      this.queryParams.search = this.waitSearch;
      this.query();
    },
    query(pageNo = 1, pageSize = 10) {
      const params = {
        regionCode: this.$vc.getCurrentRegion().code,
        startTime: this.queryParams.startTime,
        endTime: this.queryParams.endTime,
        type: this.queryParams.type,
        buildingId: this.queryParams.buildingId,
        search: this.queryParams.search,
        pageSize,
        pageNo,
      };
      this.$fly.post(queryApprovalPending, params).then((res) => {
        if (res.code !== 0) {
          return;
        } else {
          if (pageNo === 1) {
            this.$vc.emit(this.$route.path, "pagination", "init", {
              total: res.data.total,
              pageSize,
              currentPage: pageNo,
            });
          }
        }
        console.log(res.data.datas);
        this.ApprovalPendingList = res.data.datas;
      });
    },
    // 获取楼栋信息
    queryBuilding() {
      return new Promise((resolve) => {
        this.$fly
          .post(queryBuildingUrl, {
            id: "",
            code: "",
            name: "",
            regionCode: this.$vc.getCurrentRegion().communityId,
            pageNo: 1,
            pageSize: 50,
            state: 1,
          })
          .then((res) => {
            this.building = res.data.datas;
            resolve(res.data);
          });
      });
    },
    checkDeclarantName(process) {
      this.ApprovalPendingInfo = process;
      this.ApprovalPendingFlag = true;
    },
    // 查看详情
    lookDecorationInfo(id) {
      this.$router.push({
        name: "decorationInfo",
        params: {
          id,
        },
      });
    },
    //查询记录中的巡检项目
    queryRecordItem(items) {
      console.log(items);
      let res = "";
      for (let item of items) {
        res += item + "\n";
      }
      return res;
    },
    async buttonState() {
      await this.$fly.get(isApprovalUrl, null).then((res) => {
        if (res.code !== 0) {
          return;
        } else {
          if (res.data === false) {
            this.isApproverId = "pointer-events: none; opacity:0.6;";
          }
        }
      });
    },
    //查看装修项目
    queryItems(items){
      this.processItemsFlag = true
      let itemStr = "";
      for (const itemsKey in items) {
        if (items.length - 1 +'' !== itemsKey) {
          itemStr += items[itemsKey] + "、"
        }else {
          itemStr += items[itemsKey]
        }
      }
      this.processItems = itemStr;
    }
  },
};
</script>

<style  lang="stylus" scoped>
.dialog-content {
  padding: 20px 30px;

  .dialog-form {
    &-field {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: flex-start;
      font-size: 24px;

      &:not(:last-of-type) {
        margin-bottom: 30px;
      }

      .field-name {
        width: 120px;
        margin-right: 40px;
        text-align: right;
        flex: 0 0 120px;
      }

      input {
        border: 1px solid #979797;
        border-radius: 4px;
      }

      .show-rule {
        display: flex;
        flex-flow: row nowrap;

        &-name {
          width: 120px;
          flex: 0 0 120px;
          margin-right: 40px;
        }
      }
    }
  }

  .tips {
    display: flex;
    font-size: 20px;
    color: #999;
    flex-flow: row nowrap;
    justify-content: flex-start;
    margin-top: 16px;

    .icon {
      margin-top: 4px;
      margin-right: 10px;
    }

    p {
      margin-bottom: 0;
      line-height: 1.2;

      &:not(:last-of-type) {
        margin-bottom: 4px;
      }
    }
  }
}
</style>
