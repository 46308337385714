<template>
    <div class="app-container">
        <div>
            <span class="font_style">装修审批权限</span>
            <CSSelect style="margin-right: 20px; margin-left: 20px">
                <select
                        style="width: 150px"
                        v-model="userId"
                >
                    <option value="">请选择员工</option>
                    <option
                            v-for="staff in staffList"
                            :key="staff.id"
                            :value="staff.id">
                        {{ staff.name }}
                    </option>
                </select>
            </CSSelect>
            <button
                    class="btn btn-primary"
                    style="width: 50px; height: 30px; padding: 0"
                    @click="editApprover"
            >
                保存
            </button>
        </div>

        <div style="margin-top: 10px;padding-left:30px">
            <div class="font_style" style="float: left; margin:6px 20px 10px 0px;">装修项目设置</div>
<!--            <div class="hotSearch" style="margin: 0px 20px 10px 0;float: left;">-->
                <div class="hotSearch" style="margin: 0px 20px 10px 0;float: left;" :key="item.id" v-for="(item) in itemList">
                    <span class="text">{{ item.name}}</span>
                    <svg
                            class="icon"
                            aria-hidden="true"
                            style="font-size: 14px"
                            @click="deleteItem(item.id)"
                    >
                        <use xlink:href="#icon-menua-zu60"></use>
                    </svg>
                </div>
<!--            </div>-->
            <div style="float: left">
                <button
                        class="btn btn-primary"
                        style="width: 100px; height: 30px; padding: 0"
                        @click="openAddItem"
                >
                    <svg class="icon" aria-hidden="true">
                        <use xlink:href="#icon-menujiahao"></use>
                    </svg>
                    添加项目
                </button>
            </div>
        </div>

        <!--添加装修项 弹窗-->
        <CSDialog
                :dialogTitle="`添加项目`"
                :dialog-visible="addItemFlag"
                dialog-width="620px"
                @onClose="addItemFlag = false;addItemInfo.name=''"
                @onConfirm="addItem"
        >
            <template v-slot:dialog-content>
                <div class="dialog-form">
                    <div class="dialog-form-item">
                        <div class="dialog-form-item-label" style="width:130px">项目名称</div>
                        <div>
                            <input
                                    type="text"
                                    style="
                  width: 347px;
                  height: 40px;
                  padding: 0px 10px;
                  border: 1px solid #979797;
                  border-radius: 4px;
                "
                                    placeholder="限16个字"
                                    v-model="addItemInfo.name"
                            />
                        </div>
                    </div>
                </div>
            </template>
        </CSDialog>
    </div>
</template>

<script>
    import {
        queryApprover,
        queryDepartmentStaffUrl,
        setApprover,
        queryDecorationItem,
        addDecorationItem,
        deleteDecorationItem,
    } from "@/requestUrl";
    import CSSelect from "@/components/common/CSSelect";
    import CSDialog from "@/components/common/CSDialog";

    export default {
        name: "Approver",
        components: {
            CSSelect,
            CSDialog,
        },
        data() {
            return {
                approverInfo: {},
                staffList: [],
                itemList: [],
                userId: "",
                addItemInfo: {
                    regionCode: "",
                    name: ""
                },
                addItemFlag: false

            }
        },
        created() {
            this.queryApproverInfo();
            const departmentCode = this.$vc.getCurrentStaffInfo().departmentCode;
            // 获取员工列表
            this.getStaffList(departmentCode);
            this.getDecorationItem();
        },
        methods: {
            openAddItem() {
                this.addItemFlag = true
            },
            //添加检查项
            addItem() {
                this.addItemInfo.regionCode = this.$vc.getCurrentRegion().code;
                this.$fly
                    .post(addDecorationItem, this.addItemInfo).then(res => {
                    if (res.code !== 0) {
                        return
                    }
                    this.addItemInfo.name = ""
                    this.addItemFlag = false
                    this.$vc.toast("添加成功");
                    this.getDecorationItem();

                })

            },
            //删除装修项
            deleteItem(id) {
                this.$CSDialog.warning({
                    title: "提示",
                    width:'420px',
                    messageHtml: "<span>确认删除吗?</span>",
                    onConfirm: () => {
                        this.$fly.get(deleteDecorationItem, { id: id }).then((res) => {
                            if (res.code !== 0) {
                                return;
                            }
                            this.$vc.toast("删除成功");
                            this.getDecorationItem();
                            this.$CSDialog.instance.closeDialog();
                        });
                    },
                });
            },

            //设置审批人
            editApprover() {
                this.$fly
                    .get(setApprover, {
                        userId:this.userId
                    }).then(res => {
                    if (res.code !== 0) {
                        return
                    }
                    this.$vc.toast("设置成功");
                })
            },
            //查询已设置的审批人
            queryApproverInfo() {
                const _this = this;
                this.$fly
                    .get(queryApprover, null)
                    .then((res) => {
                        if (res.data) {
                            _this.approverInfo = res.data;
                            _this.userId = _this.approverInfo.userId
                        }
                    });
            },
            // 获取员工列表
            getStaffList(departmentCode) {
                this.$fly
                    .post(queryDepartmentStaffUrl, {
                        regionCode: this.$vc.getCurrentRegion().code || "",
                        departmentCode,
                        jobLevel: '',
                        isOnTheJob: true,
                    }).then(res => {
                    if (res.code !== 0) {
                        return
                    }
                    this.staffList = res.data;
                })
            },
            //查询装修项目列表
            getDecorationItem() {
                this.$fly
                    .get(queryDecorationItem, null).then(res => {
                    if (res.code !== 0) {
                        return
                    }
                    this.itemList = res.data;
                })
            },
        }
    }
</script>

<style lang="stylus" scoped>
    .app-container{
        background-color: #ffff;
        height: 750px;
        padding-top: 20px;
    }
    .hotSearch {
        display: flex;
        align-items: center;
        flex-wrap:wrap;
        div {
            display: flex;
            align-items: center;
        }

        .text {
            background-color: #D8FFF8;
            font-size: 14px;
            color: #00B694;
            border-radius: 15px;
            margin-right: 10px;
            padding: 5px 10px;
        }
    }

    .dialog-form {
        padding: 27px 30px;
        font-size: 24px;
        color: #000;

        &-item {
            &:not(:last-of-type) {
                margin-bottom: 23px;
            }

            &-label {
                width: 206px;
                height: 33px;
                text-align: right;
                margin-right: 40px;
                display: inline-block;
                vertical-align: middle;
                line-height: 33px;
            }

            & > div {
                display: inline-block;
                vertical-align: middle;

                input {
                    &::placeholder {
                        color: #999;
                    }

                    width: 140px;
                    height: 40px;
                    border-radius: 4px;
                    padding: 0 5px;
                    border: 1px solid #979797;
                    vertical-align: middle;
                    padding-left: 10px;
                }
            }
        }
    }

    .font_style{
        width: 84px;
        height: 20px;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 20px;
        color: #000000;
        opacity: 1;
        margin-left 30px;
        vertical-align: middle;
    }
</style>
