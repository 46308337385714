<template>
    <div class="utill-container">
        <CSTabBar
                :tabbar="tabBar"
                :checked-tab="checkedTaBar"
                @changeTabBar="changeTabBar"
        />
        <template v-if="checkedTaBar === 1">
            <ApprovalPending></ApprovalPending>
        </template>
        <template v-else-if="checkedTaBar === 2">
            <ApprovedDrawing></ApprovedDrawing>
        </template>
        <template v-else-if="checkedTaBar === 3">
            <Approver></Approver>
        </template>
    </div>
</template>

<script>

    import CSTabBar from "@/components/common/CSTabBar";
    import ApprovalPending from "@/views/decoration/ApprovalPending"
    import ApprovedDrawing from "@/views/decoration/ApprovedDrawing"
    import Approver from "@/views/decoration/Approver"

    export default {
        name: "Decoration",
        components: {
            ApprovedDrawing,
            ApprovalPending,
            CSTabBar,
            Approver,
        },
        data() {
            return {
                tabBar: {
                    1: "待审批",
                    2: "已审批",
                },
                checkedTaBar: 1,
                jobLevel:""
            }
        }, methods: {
            changeTabBar(index) {
                console.log(index)
                this.checkedTaBar = +index;
            }
        },
        created() {
            const _this = this;
            let level = this.$vc.getCurrentStaffInfo().jobLevel
            if (level === 1){
                _this.tabBar = {
                    1: "待审批",
                    2: "已审批",
                    3: "设置"
                }
            }
        },
    }
</script>

<style scoped>

</style>
